<template>
    <div v-if="user.isCoordinator">
        <div class="flex justify-between">

            <h2 class="flex text-2xl p-4 mb-2">Profesores</h2>
            <select
                class="flex border border-gray-200 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary rounded-lg w-1/6 p-4 mb-2 justify-end"
                placeholder="Espcialidades"
                :disabled="loading || loadingPeriod"
                v-model="specId"
                v-if="specialization.length > 0 && supervising.length > 0 && (!loading && !loadingPeriod)"
                @change="filterTeachers"
                >
                <option :value="specializations.id" v-for="specializations in specialization" :key="specializations.id">{{ specializations.title }}</option>
                <option v-if="specialization.length > 1" value="todos">TODOS</option>
            </select>
        </div>

      <div class="flex flex-wrap -mx-2">
            <div class="p-8 w-full" v-if="loading || loadingPeriod">
                <a-skeleton class="my-1 h-20 w-full rounded-xl" />
                <a-skeleton class="my-1 h-20 w-full rounded-xl" />
                <a-skeleton class="my-1 h-20 w-full rounded-xl" />
                <a-skeleton class="my-1 h-20 w-full rounded-xl" />
            </div>
            <template v-if="!loading && !loadingPeriod">
                <div class="w-1/2 lg:w-1/3 xl:w-1/5 p-2" v-for="teacher in teachers" :key="teacher.id" >
                    <a-card @click="$router.push({ name: 'teachers.show', params: { id: teacher.id }})" class="pb-2">
                        <div class="flex items-center flex-col justify-center truncate">
                            <a-avatar :text="teacher.first_name" :picture="teacher.profile_picture" size="64" class="mb-4"/>
    
                            <p class="font-bold text-lg truncate">
                                {{ `${teacher.full_name}` }}
                            </p>
                            <p class="truncate">
                                {{ teacher.phone }}
                            </p>
                            <p>
                                {{ teacher.email }}
                            </p>
                        </div>
                    </a-card>
                </div>
            </template>
            <div class="w-full text-center" v-if="!teachers.length && (!loading && !loadingPeriod)">
                <h2 class="text-2xl mt-4">No supervisas ningún profesor</h2>
            </div>
        </div>
    </div>
    <div v-else>No tienes acceso a esta vista</div>
</template>

<script>
export default {
    props: {
        loading: { required: false, type: Boolean, default: false },
        loadingPeriod: { required: false, type: Boolean, default: false },
        user: { required: true, type: Object },
        supervising: { required: false, type: Array, default: () => [] },
        specialization: { required: false, type: Array}
    },
    data: () => ({
        specId: 0,
        teachers: []
    }),
    watch: {
        supervising(val){
            if(val){
                this.specId = this.specialization[0].id
                this.teachers = val.filter(spec => spec.specialization_id === this.specialization[0].id)
            }
        }
    },

    methods: {
        filterTeachers(){
            if(this.specId === 'todos' || !this.specId){
                this.teachers = this.supervising;
            } else{
                this.teachers = this.supervising.filter(teachers => teachers.specialization_id === this.specId)
            }
        }
    },
    mounted() {

        if(this.supervising.length != 0 ){
            this.specId = this.specialization[0].id
            this.teachers = this.supervising.filter(teachers => teachers.specialization_id === this.specId)
        }
     
    }
}
</script>

<style scoped>

</style>
